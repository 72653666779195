import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src199531276/src/mojility.ca/src/components/layout.js";
import SEO from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Contact Information" mdxType="SEO" />
    <h1>{`Business Contact Information`}</h1>
    <p>{`Phone (Toronto): (647) 365-5878`}<br />{`
Email: `}<a href="mailto:info@mojility.ca"><a parentName="p" {...{
          "href": "mailto:info@mojility.ca"
        }}>{`info@mojility.ca`}</a></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      